<template>
  <div>
    <div v-if="!isLoading" class="exchange ">
      <!--
      //# Section Title -->
      <section class="l-title" data-scroll-section>
        <h1
          class="title title-general"
          data-scroll="fade-up"
          data-scroll-delay="1"
        >
          The Exchange
        </h1>
      </section>
      <section class="l-articles align-left" data-scroll-section>
        <h3 class="subtitle" data-scroll="fade-up" data-scroll-delay="3">
          Article Archive
        </h3>
        <ul class="articles-list" data-scroll="fade-up" data-scroll-delay="4">
          <li
            class="article-item"
            v-for="(article, index) in articles"
            :key="index"
          >
            <ExchangeArticleCard :article="article" />
          </li>
        </ul>
      </section>
    </div>
    <div class="loader" v-else>
      <transition name="fade">
        <Spinner />
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ExchangeArticleCard from '@/pages/exchange/ExchangeArticleCard';
import Spinner from '@/components/Spinner';

export default {
  name: 'Exchange',
  metaInfo() {
    return {
      title: 'Ascentador | The Exchange',
      meta: [
        {
          name: 'description',
          content:
            'Ascentador partners deliberately spend time listening, reading, talking and writing on a range of topics impacting careers, talent and the workplace. We welcome guest authors to participate in this exchange of ideas with us and with our followers.',
        },
      ],
    };
  },
  components: {
    ExchangeArticleCard,
    Spinner,
  },
  data() {
    return {
      articles: [],
      currentPage: '',
      API: process.env.VUE_APP_API,
      isLoading: true,
    };
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    async getArticles() {
      try {
        const response = await axios.get(`${this.API}/article?all=true`);
        this.articles = response.data.data;
        this.$emit('view-mounted');
      } catch {
        console.log('error del getArticles - exchange');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
