<template>
  <div class="c-card" data-scroll="fade-up">
    <ImageItem :alt="article.slug" class="card-image" :source="article.image" />
    <div class="card-content">
      <h4 class="title-item" v-html="article.title"></h4>
      <p class="text" v-html="article.content"></p>
      <!-- @click="$emit('loading-article')" -->
      <router-link class="button btn-small" :to="{ name: `${(article.type == 'classic') ? `exchange.${article.slug}` : `questionnaire.${article.slug}`}` }">
        Read more
      </router-link>
    </div>
  </div>
</template>

<script>
import ImageItem from '@/components/ImageItem';

export default {
  components: {
    ImageItem,
  },
  name: 'ExchangeArticleCard',
  props: {
    article: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
